<template>
	<v-container fluid tag="section">
		<ti-card :icon="$icons.star">
			<template #title>Podcast Awards</template>
			<v-data-table :headers="headers" :items="podcast_awards" item-key="name" :search="search" @click:row="editItem" :item-class="() => 'hover-pointer'">
				<template v-slot:top>
					<v-row justify="end">
						<v-col cols="12" lg="6">
							<v-text-field
								v-model="search"
								label="Search"
								prepend-icon="mdi-magnify"
								class="mx-4"
							></v-text-field>
						</v-col>
					</v-row>
				</template>
			</v-data-table>
		</ti-card>
	</v-container>
</template>

<script>
import {call, sync} from "vuex-pathify";

export default {
	name: "PodcastAwardsIndex",
	computed: {
		podcast_awards: sync('podcast_awards/podcast_awards')
	},
	data: () => ({
		search: '',
		headers: [
			{
				text: 'Year',
				align: 'start',
				sortable: true,
				value: 'year',
			},
		]
	}),
	beforeMount() {
		this.init()
	},
	methods: {
		getPodcastAwards: call('podcast_awards/getPodcastAwards'),
		init() {
			this.getPodcastAwards(null)
		},
		editItem(data) {
			this.$router.push({name: 'PodcastAwardsEdit', params: {id: data.id}})
		},
	}
}
</script>